<template>
	<v-container v-if="product.id == -1">{{ $t("product.loading_specific") }}</v-container>
	<v-container fluid v-else>
		<v-container>
			<!-- FIXME -->
			<v-row dense>
				<v-col>
					<template v-for="(item, index) in parents">
						<router-link class="bread" :to="item.href" :key="item.href">{{ item.text}}</router-link>
						<span v-if="index < parents.length - 1" :key="item.href">&nbsp;&nbsp;>&nbsp;&nbsp;</span>
					</template>
<!--
					<v-breadcrumbs :items="parents">
						<template v-slot:divider>
							<v-icon>mdi-chevron-right</v-icon>
						</template>
					</v-breadcrumbs>
-->
				</v-col>
			</v-row>

			<v-row dense>
				<v-col class="d-flex flex-column" cols="12">
					<v-card class="flex d-flex flex-column" outlined tile elevation="24">
						<v-row dense class="incenter seda relatko">
							<v-col class="tileTagParent">
								<v-avatar class="tileTag" v-if="product.tileTag != 'none'"><v-img :src="product.tileTag"></v-img></v-avatar>
								<v-card-title class="headline">{{ product.product }}</v-card-title>
							</v-col>
							<v-btn v-if="this.$store.getters.is_authenticated" class="smaller btn_cat" right :title="$t('product.btn_title_add_to_catalogue')" @click.prevent="hello(product.id)"><v-icon>mdi-folder-multiple-plus</v-icon></v-btn>
						</v-row>
						<v-row dense>
							<v-col class="incenter">
								<v-avatar class="profile" size="120" tile>
									<v-img contain :src="product.img" @click="overlay = !overlay"></v-img>
								</v-avatar>
								<v-overlay :value="overlay" @click.native="overlay = false">
									<v-img :src="product.img" class="fit_horizontally"></v-img>
								</v-overlay>
							</v-col>
							
						</v-row>
						<v-row>
							<v-col>
								<v-card-subtitle class="incenter">
									{{ product.description_short }}
									<br><br>
									<v-simple-table dense v-if="product.prices.length == 2">
										<tbody>
											<tr><td style="text-align:right;">Odporúčaná cena:</td><td style="text-align:left;">{{ product.prices[0] }}€ bez DPH</td></tr>
											<tr><td style="text-align:right;"><b>Vaša cena:</b></td><td style="text-align:left;"><b>{{ product.prices[1] }} € bez DPH</b></td></tr>
										</tbody>
									</v-simple-table>
									<v-simple-table dense v-if="product.prices.length == 1">
										<tbody>
											<tr><td>Cena na vyžiadanie</td></tr>
										</tbody>
									</v-simple-table>
								</v-card-subtitle>
							</v-col>
						
						</v-row>
						<!-- </div> -->
					</v-card>

						<!-- <div class="d-flex flex-no-wrap justify-space-between">
							<div>
								<v-card-title class="headline">{{ product.product }}</v-card-title>
								<v-card-subtitle>{{ product.description_short }}</v-card-subtitle>
							</div>
							<v-avatar class="ma-3" size="150" tile>
								<v-img contain :aspect-ratio="16 / 9" :src="product.img"></v-img>
							</v-avatar>
						</div> -->
				</v-col>
			</v-row>

			<v-card>
				<v-tabs v-model="tab" centered grow icons-and-text>
					<v-tabs-slider></v-tabs-slider>
					<v-tab class="smaller">{{ $t('product.tab_description') }}<v-icon>mdi-view-headline</v-icon></v-tab>
					<v-tab class="smaller">{{ $t('product.tab_properties') }}<v-icon>mdi-file-key</v-icon></v-tab>
					<v-tab class="smaller">{{ $t('product.tab_parameters') }}<v-icon>mdi-ballot</v-icon></v-tab>
					<v-tab class="smaller">{{ $t('product.tab_files') }}<v-icon>mdi-download</v-icon></v-tab>
					<v-tab class="smaller">{{ $t('product.tab_ordering') }}<v-icon>mdi-shopping</v-icon></v-tab>
					<!-- https://pictogrammers.github.io/@mdi/font/5.4.55/ -->
				</v-tabs>
				<v-tabs-items v-model="tab">
					<!-- DESCRIPTION -->
					<v-tab-item>
						<v-card flat>
							<v-card-text class="gugi">
								
							{{ product.text }}

							</v-card-text>
						</v-card>
					</v-tab-item>
					<!-- PROPERTIES -->
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr v-for="item in product.properties" :key="item">
												<td class=""><v-icon>mdi-menu-right</v-icon>{{ item }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<!-- PARAMETERS -->
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr v-for="item in product.parameters" :key="item.parameter">
												<td><b class="">{{ item.parameter + ":" }}</b></td>
												<td class="">{{ item.value }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
					<!-- FILES -->
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-list dense>
									<v-list-item-group color="primary">
										<v-list-item :href="'/adm/partner_cc_create_data.php?product_id=' + product.id" target="_blank">
											<v-list-item-icon>
												<v-icon v-text="katalog.icon"></v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $t("product.file_catalogue_sk") }}</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item @click.stop="dialog = true" v-if="!this.$store.getters.is_authenticated && product.files_total > 0">
											<v-list-item-icon>
												<v-icon>mdi-do-not-disturb</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title>{{ $t("product.files_after_login") + " : " + product.files_total }}</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-list-item v-else v-for="n in product.files" :key="n.id" @click="get_att(n.fileSrc)">
											<v-list-item-icon>
												<v-icon v-text="n.icon"></v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title v-if="n.description.length == 0" v-text="n.label"></v-list-item-title>
												<v-list-item-title v-else v-text="n.label + ' - ' + n.description"></v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-card-text>
						</v-card>
					</v-tab-item>
					
					<!-- ORDERING-->
					<v-tab-item>
						<v-card flat>
							<v-card-text>
								<v-list dense>
									<v-list-item-group color="primary">
										<v-list-item v-for="n in product.ordering" :key="n.id" :to="'/product/' + n.id">
											<v-list-item-icon>
												<v-icon v-text="n.icon"></v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title><b>{{ n.product }}</b> - {{ n.description_short }}</v-list-item-title											>
											</v-list-item-content>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-container>
		<v-dialog v-model="dialog" max-width="290">
			<v-card>
				<v-card-title class="headline">{{ $t('product.login_required') }}</v-card-title>
				<v-card-text>{{ $t('product.login_required_disclaimer') }}</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="dialog = false">{{ $t('product.login_required_btn_cancel') }}</v-btn>
					<v-btn color="green darken-1" text @click="dialog = false" route to="/login">{{ $t('product.login_required_btn_login') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog2" hide-overlay persistent>
			<v-card>
				<v-card-text>{{ $t('product.alert_added_to_catalogue') }}</v-card-text>
			</v-card>
		</v-dialog>	
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	data: () => ({
		dialog: false,
		dialog2: false,
		drawer: false,
		group: null,
		katalog: {
			icon: "mdi-download",
		},
		product: {
			id: -1,
		},
		parents: [],
		tab: null,
		seen: false,
		overlay: false
	}),
	methods: {
		create_route(iid) {
			this.$router
			.push({ name: "product", params: { id: iid } })
			.catch(() => {});
		},
		get_att(att_name) {
			ApiService.get("/get_att_token/pl/" + att_name).then((response) => {
				console.log(response)
				let token = response.data.file_token
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = 'https://sectro.sk/api/get_att/pl/' + token;
				a.download = att_name;
				document.body.appendChild(a);
				a.click();
				//window.open('https://sectro.sk/api/get_att/pl/' + token, '_blank')
				// AUTHORIZED WITH FILES
				//let blob = new Blob([response.data], { type: 'application/octet-stream' })
				//var reader = new FileReader()
    //                            let blob = new Blob([response.data], { type: response.headers['content-type'] })
			//	let urlik = URL.createObjectURL(blob)
        //                        window.open(urlik, '_blank')
                                //let link = document.createElement('a')
				//window.open(blob, '_blank')
                                //link.href = window.webkitURL.createObjectURL(blob)
				//link.target = "_blank"
				//link.click()
                                //reader.onloadend = function() {window.open(reader.result)}
                                //reader.readAsDataURL(blob)
			}).catch(() => {})
		},
		highlight(text) {
			document.designMode = "on"; 
			var sel = window.getSelection(); 
			sel.collapse(document.body, 0);
			while (window.find(text)) { 
				document.execCommand("HiliteColor", false, "yellow"); 
				sel.collapseToEnd(); 
			}
			document.designMode = "off";

		},
		hello(ID_pl) {
			this.dialog2 = true
			this.$store.commit('add_to_catalogue', ID_pl)
		}
	},
	watch: {
		group() {
			this.drawer = false;
		},
		dialog2(val) {
			if (!val) return
			setTimeout(() => (this.dialog2 = false), 1000)
		},
	},
	mounted() {
		ApiService.get("/pl/" + this.$route.params.id, false).then((response) => {
			// AUTHORIZED WITH FILES
			this.product = response.data.product
			this.parents = response.data.parents
			// window.setTimeout(this.highlight('2300'), 3000);
		}).catch(() => {})
	},
	updated() {
		// var index = innerHTML.indexOf(text);
		// 	innerHTML = innerHTML.substring(0,index) + "<span class='highlight'>" + innerHTML.substring(index,index+text.length) + "</span>" + innerHTML.substring(index + text.length);
		// 	inputText.innerHTML = innerHTML;
		// }
		this.$forceNextTick(() => {
			if(typeof this.$route.params.search_string !== 'undefined') {
				this.highlight(decodeURI(this.$route.params.search_string))
			}
		})
	}
};
</script>

<style scoped>

.gugi {
	text-align: justify;
}
.v-card {
	margin-bottom: 5px;
}
.v-tab {
	text-transform: none;
	font-size: 0.75rem;
}

.v-card__title {
	padding-top: 0px;
}

.container {
	padding-top: 0px;
}
.smaller {
	font-size: 0.58em;
}
.incenter {
	text-align: center;
}
.relatko {
	position: relative;
}
.btn_cat {
	position: absolute;
	top: 0px;
	right: 0px;
}
.lolo {
	display : flex;
	align-items : center;
	justify-content: center;
}
.headline {
	font-size: 1.2rem !important;
	display: block;
	padding: 0;
}
.seda {
	background-color: rgb(245, 245, 245);
	margin: 0;
}
.tileTagParent {
	position: relative;
}
.tileTag {
	position: absolute;
	top: -25px;
	left: -15px;
}
.bread {
	color: #1976d2;
	text-decoration: none;
	cursor: pointer;
	font-size: 14px;
}
.fit_horizontally {
	max-width: 100%;
	justify: center;
}
</style>
